
function GlobalHelper() {
    var self = this;

    self.config = self.config || {};
    self.config.baseUrl = process.env.VUE_APP_OLD_SERVER_BASE_URL;

    self.getBaseUrl = function () {
        var base = self.config.baseUrl.slice(-1) == '/' ? self.config.baseUrl : self.config.baseUrl + '/';

        return base;
    };

    self.getActionUrl = function (action, controller) {
        var base = self.config.baseUrl.slice(-1) == '/' ? self.config.baseUrl : self.config.baseUrl + '/';

        if (action) {
            return base + controller + '/' + action;
        } else {
            return base + controller;
        }
        
    };

}

let ghelper = new GlobalHelper();

function abbrCount(c) {
    let abbrNo;
    if (c >= 10000) {
        abbrNo = Math.floor(c/1000) * 1000 / 10000;
    } else {
        if (c) {
            return c;
        } else {
            return 0;
        }
    }

    return abbrNo.toFixed(1) + '萬';
}

function HSVtoRGB(h, s, v) {
    var r, g, b, i, f, p, q, t;
    if (arguments.length === 1) {
        s = h.s, v = h.v, h = h.h;
    }
    i = Math.floor(h * 6);
    f = h * 6 - i;
    p = v * (1 - s);
    q = v * (1 - f * s);
    t = v * (1 - (1 - f) * s);
    switch (i % 6) {
        case 0: r = v, g = t, b = p; break;
        case 1: r = q, g = v, b = p; break;
        case 2: r = p, g = v, b = t; break;
        case 3: r = p, g = q, b = v; break;
        case 4: r = t, g = p, b = v; break;
        case 5: r = v, g = p, b = q; break;
    }
    return {
        r: Math.round(r * 255),
        g: Math.round(g * 255),
        b: Math.round(b * 255)
    };
}

function getUrlParams() {
    let uri = window.location.href.split('?');
    var getVars = {};
    if (uri.length == 2) {
        let vars = uri[1].split('&');
        let tmp = '';
        vars.forEach(function (v) {
            tmp = v.split('=');
            if (tmp.length == 2) {
                let loweredVar = tmp[0].toLowerCase();
                getVars[loweredVar] = tmp[1];
            }

        });
    }

    return getVars;
}

function gotoMaterialEdit(event, tmr, tl) {
    var appUrl = ghelper.getActionUrl('Edit', 'Material');
    appUrl = appUrl + '?v=' + tmr.VideoId + '&reqId=' + tmr.Id + '&tl=' + tl;

    if (event.ctrlKey || event.shiftKey) {
        window.open(appUrl);
    } else {
        location.href = appUrl;
    }
}

function OpenPlayVideo(vid, lang, start) {
    let appUrl;

    if (start) {
        appUrl = ghelper.getActionUrl('PlayVideoBS', 'Video') + "?v=" + vid + '&lang=' + lang + '&s=' + Math.floor(start);
    } else {
        appUrl = ghelper.getActionUrl('PlayVideoBS', 'Video') + "?v=" + vid + '&lang=' + lang;
    }

    var win = window.open(appUrl, '_blank');
    win.focus();
}

function getDateString (dt) {
    if (dt) {
        var d = new Date(dt);
        return d.getFullYear() + '-' +
            zeroPadding((d.getMonth() + 1), 2) + '-' +
            zeroPadding(d.getDate(), 2);
    }

    return null;
}

function zeroPadding (n, strLen) {
    return ("0" + n).slice(-strLen);
}

function fmtAuthorAvatorUrl(author) {
    if (author.AvatorFile) {
        if (author.AvatorFileModifiedCount) {
            return '/Content/Users/' + author.AvatorFile + '?v=' + author.AvatorFileModifiedCount;
        } else {
            return '/Content/Users/' + author.AvatorFile;
        }
    } else {
        return require('@/assets/image/lexprogress-author.jpg');
    }
}

function gotoAuthorProfile (tm) {
    if (tm.AuthorId) {
        var url = ghelper.getActionUrl('Show', 'Account');
        location.href = url + '/' + tm.AuthorId;
    }
}

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export {
    ghelper,
    HSVtoRGB,
    getUrlParams,
    abbrCount,
    gotoMaterialEdit,
    OpenPlayVideo,
    getDateString,
    fmtAuthorAvatorUrl,
    gotoAuthorProfile,
    getCookie,
};