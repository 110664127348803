<template lang="html">
    <!-- Modal -->
    <div class="modal fade" ref="alertDialog" id="alertDialog" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{{fmtTitle}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" v-html="msg">
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-dismiss="modal">{{$t('element_b_dialog.ok')}}</button>
                </div>
            </div>
        </div>
    </div>
</template>
    
<script>
    export default {
        props: {
            
        },
        data: function() {
            return {
                title: null,
                msg: null,
            };
        },
        methods: {
            onToggle: function (msg, title) {
                this.msg = msg;
                this.title = title;

                $('#alertDialog').modal();
            },
        },
        mounted: function () {
            this.$dialog.$bus.$on('toggle', this.onToggle);
        },
        computed: {
            fmtTitle: function () {
                if (this.title) {
                    return this.title;
                }

                return this.$t('element_b_dialog.please_notice')
            }
        },
    };
</script>
    
<style lang="css">
    
</style>