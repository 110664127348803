import BDialog from './BDialog.vue'

let Plugin = function (Vue) {
    this.Vue = Vue;
};


Plugin.prototype.alert = function (msg, title) {
    this.Vue.$dialog.$bus.$emit('toggle', msg, title);
};

Plugin.install = function (Vue, options = {}) {
    if (Vue.prototype.$dialog) {
        return
    }

    Vue.Dialog = new Plugin(Vue, options);

    Vue.$dialog = Vue.Dialog;
    Vue.prototype.$dialog = Vue.Dialog;

    Vue.$dialog.$bus = new Vue();

    /*
    Object.defineProperties(Vue.prototype, {
        $dialog: {
            get() {
                return Vue.Dialog;
            }
        }
    });
    */

    /**
     * Registration of <Dialog/> component
     */
    const componentName = options.dialogComponentName || 'BDialog';
    Vue.component(componentName, BDialog);
}

export default Plugin