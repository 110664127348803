<template lang="html">
    <header>
        <nav>
            <div class="navbar navbar-expand-md bg-white fixed-top shadow-sm  navbar-light">
                <div class="container d-flex justify-content-between">
                    <a href="/"
                       class="header-logo navbar-brand d-flex align-items-center">
                        <img src="/img/header-logo.svg" alt="">
                    </a>
                    <div class="d-flex">
                        <button v-if="user && showSearchBox" class="navbar-toggler search-btn" type="button" data-toggle="collapse" data-target="#navbarCollapseSearch"
                                aria-controls="navbarCollapseSearch" aria-expanded="false" aria-label="Toggle navigation">
                            <img src="@/assets/image/header-search-btn.svg" alt="">
                        </button>
                        <div v-if="isPhone && user" class="dropdown nav-hidden-desktop">
                            <button class="btn language-btn px-2" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa-bell" :class="[notificationCount>0?'fas':'far']" :style="{'color':notificationCount>0?'#456CEB':'unset'}"></i>&nbsp;<span>{{notificationCount}}</span>
                            </button>
                            <user-notification-panel class="dropdown-menu dropdown-menu-right"
                                                     @unread_notification_count_updated="onNotificationCountUpdated"></user-notification-panel>
                        </div>
                        <div class="dropdown nav-hidden-desktop">
                            <button class="btn language-btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fas fa-globe-asia"></i>&nbsp;<span>{{fmtCurrentLang}}</span>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="z-index: 2100000000">
                                <a class="dropdown-item" @click="switchTranslation('zh-Hant')">{{$t('element_header.traditionalChinese')}}</a>
                                <a class="dropdown-item" @click="switchTranslation('zh-Hans')">{{$t('element_header.simplifyChinese')}}</a>
                                <a v-if="user && (user.IsPreviewer || user.IsContentManager || user.IsAdmin)" class="dropdown-item" @click="switchTranslation('vi-VN')">{{$t('element_header.vietnamese')}}</a>
                            </div>
                        </div>
                        <div class="d-flex">
                            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                                    aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>
                            <div class="user-login-wrap nav-hidden-desktop">
                                <!-- <a class="ml-auto mr-0" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="/Content/Users/gZlm2jZm2Q.jpg" title="湯勺" class="w-100 subscribed-image ml-2" style="cursor: pointer;"></a> -->
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <a class="dropdown-item" href="#">個人設定</a>
                                    <a class="dropdown-item" href="#">登出</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="user && user.HashedUserId" id="navbarCollapse" class="collapse navbar-collapse flex-grow-1">
                        <ul :class="['navbar-nav', showSearchBox?'mr-auto':'ml-auto']">
                            <li v-if="user.IsPremiumUser" class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuCategroy"
                                   role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{$t('element_header.videoLibrary')}}
                                </a>
                                <div v-bind:class="['dropdown-menu', isPhone?'scrollable-menu':'']"
                                     aria-labelledby="navbarDropdownMenuCategroy">
                                    <a class="dropdown-item" :href='getAbsoluteAction("ListVideoBS", "Video")+"?lt=5"'>{{$t('element_header.likeVideo')}}</a>
                                    <a class="dropdown-item" :href='getAbsoluteAction("ListVideoBS", "Video")+"?lt=4"'>{{$t('element_header.watchVideo')}}</a>
                                    <div>
                                        <div class="dropdown-divider"></div>
                                        <!--eslint-disable-next-line-->
                                        <a v-for="cate in categories" class="dropdown-item"
                                           :href="fmtCategoryUrl(cate)">{{cate.Name}}</a>
                                    </div>
                                </div>
                            </li>
                            <li v-if="!user.IsPremiumUser" class="nav-item">
                                <a class="nav-link" :href='getAbsoluteAction(null, "Material")'>{{$t('element_header.textBook')}}</a>
                            </li>
                            <li v-if="!user.IsPremiumUser" class="nav-item">
                                <a class="nav-link" :href='getAbsoluteAction("Collections", "Material")'>{{$t('element_header.materialCollections')}}</a>
                            </li>
                            <li v-if="!user.IsPremiumUser" class="nav-item">
                                <a class="nav-link" :href='getAbsoluteAction("PracticeBS", "Lex")'>{{$t('element_header.vocabularyPractice')}}</a>
                            </li>
                            <li v-if="!user.IsPremiumUser" class="nav-item">
                                <a class="nav-link" :href='getAbsoluteAction("LexProgress", "LeaderBoard")'>{{$t('element_header.1200singleWordProgress')}}</a>
                            </li>
                            <li v-if="user.IsPremiumUser" class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuTm"
                                   role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{$t('element_header.videoLearn')}}
                                </a>
                                <div v-bind:class="['dropdown-menu', isPhone?'scrollable-menu':'']"
                                     aria-labelledby="navbarDropdownMenuTM">
                                    <div v-if="user.IsTranslator || user.IsContentManager || user.IsAdmin || user.IsPreviewer">
                                        <a class="dropdown-item" :href='getAbsoluteAction("My", "Material")'>{{$t('element_header.myVideoLearn')}}</a>
                                        <div class="dropdown-divider"></div>
                                    </div>
                                    <a class="dropdown-item" :href='fmtMaterialIndexUrl'>{{$t('element_header.textBook')}}</a>
                                    <a class="dropdown-item" :href='getAbsoluteAction("Collections", "Material")'>{{$t('element_header.materialCollections')}}</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" :href='getAbsoluteAction("PracticeBS", "Lex")'>{{$t('element_header.vocabularyPractice')}}</a>
                                    <a class="dropdown-item" :href='getAbsoluteAction("LexProgress", "LeaderBoard")'>{{$t('element_header.1200singleWordProgress')}}</a>
                                    <div v-if="user.IsPremiumUser">
                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item" :href='getAbsoluteAction("ListRoom", "Speaking")'>{{$t('element_header.themeOrientedClasses')}}</a>
                                    </div>

                                </div>
                            </li>
                            <component v-if="user.IsAdmin || user.IsContentManager"
                                       v-bind:is="componentMenuContentManager"></component>
                            <component v-if="user.IsAdmin" v-bind:is="componentMenuAdmin"></component>
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{$t('element_header.system')}}
                                </a>
                                <div v-bind:class="['dropdown-menu', isPhone?'scrollable-menu':'', !user.IsPremiumUser&&!isPhone?'dropdown-menu-right':'']"
                                     aria-labelledby="navbarDropdownMenuLink">
                                    <a class="dropdown-item" href="https://youtu.be/4MWLYOKyq8Y" target="_blank">{{$t('element_header.videoPlaySample')}}</a>
                                    <a class="dropdown-item" href="https://youtu.be/BDLub25cD-g" target="_blank">{{$t('element_header.sayFllowSample')}}</a>
                                    <a v-if="user && (user.IsSpecialUser || user.IsPreviewer)" class="dropdown-item" :href="fmtTranslationHelpUrl" target="_blank">{{$t('element_header.translationClaim')}}</a>
                                    <!--
                    <div v-if="user.IsPremiumUser">
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="https://youtu.be/Zxd70mznkNU" target="_blank">{{$t('element_header.corpusSearchSample')}}</a>
                        <a class="dropdown-item" href="https://paper.dropbox.com/doc/--AoJ1CGndq3Ft6sWnyE0iOmROAg-frZFOMaxm8Hu8T77E9pnV" target="_blank">{{$t('element_header.tocSample')}}</a>
                    </div>
                    <div>
                        <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="https://youtu.be/w8ulAX6Hfag" target="_blank">{{$t('element_header.newTtSample')}}</a>
                    </div>
                    -->
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" :href='getAbsoluteAction("Edit", "Account")'>{{$t('element_header.userSet')}}</a>
                                    <a v-if="user.IsAdmin || user.IsContentManager" class="dropdown-item" :href='getAbsoluteAction("LearningSetting", "Account")'>{{$t('element_header.languageSet')}}</a>
                                    <a class="dropdown-item" :href='getAbsoluteAction("LearningProfile", "Account")'>{{$t('element_header.learnFile')}}</a>
                                    <a class="dropdown-item" :href='getAbsoluteAction("Show", "Account")'>{{$t('element_header.personalFile')}}</a>
                                    <a class="dropdown-item" :href='getAbsoluteAction("LogOff", "Account")'>{{$t('element_header.loginOut')}}</a>
                                </div>
                            </li>
                        </ul>
                        <search-box v-if="user && showSearchBox" :user="user"></search-box>
                    </div>
                    <div v-else id="navbarCollapse" class="collapse navbar-collapse flex-grow-1">
                        <!-- 舊版註冊按鈕
        <a class="ml-auto mr-0" :href='getAbsoluteAction("Login", "Home")'>登入 / 註冊</a>
        -->
                        <!-- 這做什麼的？
        <div class="header-search-input-wrapper mr-3"><input type="search" name="q" placeholder="請輸入搜尋的詞彙" aria-label="Search through site content" class="header-search"><img alt="" src="/img/search-button-red.svg" class="search-button-red"></div>
        <div class="user-login-wrap nav-hidden-mobile">
            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a class="dropdown-item" href="#">個人設定</a>
                    <a class="dropdown-item" href="#">登出</a>
            </div>
        </div>
        -->
                        <div class=signIn-wrap> <button class="btn " data-toggle="modal" data-target="#signInModal">{{$t('element_header.login')}}</button></div>
                        <a class="signUp-wrap"><div class="w-100 ml-3"><button class="btn btn-line-board" data-toggle="modal" data-target="#signUpModal">{{$t('element_header.signUp')}}</button></div></a>
                    </div>
                    <!-- 登入Modal -->
                    <div class="modal fade" id="signInModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">{{$t('element_header.login')}}</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <p>{{$t('element_header.loginStartStep1')}}</p>
                                    <ul class="login-icon-wrapper">
                                        <li><a href="#" class="login_fb" title="Facebook登入"><img class="100%" src="@/assets/image/base-icon-2.png" alt="Facebook登入" @click="login('Facebook', true)"></a></li>
                                        <li><a href="#" class="login_google" title="Google登入"><img class="100%" src="@/assets/image/base-icon-3.png" alt="Google登入" @click="login('Google', true)"></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 註冊Modal -->
                    <div class="modal fade" id="signUpModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">{{$t('element_header.signUpStart')}}</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <p>{{fmtRegisterDesc}}</p>
                                    <ul class="login-icon-wrapper">
                                        <li><a href="#" class="login_fb" title="Facebook登入"><img class="100%" src="@/assets/image/base-icon-2.png" alt="Facebook登入" @click="login('Facebook', false)"></a></li>
                                        <li><a href="#" class="login_google" title="Google登入"><img class="100%" src="@/assets/image/base-icon-3.png" alt="Google登入" @click="login('Google', false)"></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="user && showSearchBox" id="navbarCollapseSearch" class="collapse navbar-collapse flex-grow-1">
                        <div class="header-search-input-wrapper hidden-desktop">
                            <input type="search" name="q" placeholder="搜尋影片名稱" aria-label="Search through site content" class="header-search">
                            <!--
            <select class="header-search-select">
                <option>搜尋文本</option>
                <option>搜尋例句</option>
                <option>搜尋片名</option>
            </select>
            -->
                            <img alt="" src="/img/search-button-red.svg" class="search-button-red">
                        </div>
                    </div>
                    <div v-if="!isPhone && user" class="dropdown nav-hidden-mobile">
                        <button class="btn language-btn px-2" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fa-bell" :class="[notificationCount>0?'fas':'far']" :style="{'color':notificationCount>0?'#456CEB':'unset'}"></i>&nbsp;<span>{{notificationCount}}</span>
                        </button>
                        <user-notification-panel class="dropdown-menu dropdown-menu-right"
                                                 @unread_notification_count_updated="onNotificationCountUpdated"></user-notification-panel>
                    </div>
                    <div class="dropdown nav-hidden-mobile">
                        <button class="btn language-btn px-2" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-globe-asia"></i>&nbsp;<span>{{fmtCurrentLang}}</span>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="z-index: 2100000000">
                            <a class="dropdown-item" @click="switchTranslation('zh-Hant')">{{$t('element_header.traditionalChinese')}}</a>
                            <a class="dropdown-item" @click="switchTranslation('zh-Hans')">{{$t('element_header.simplifyChinese')}}</a>
                            <a v-if="user && (user.IsPreviewer || user.IsContentManager || user.IsAdmin)" class="dropdown-item" @click="switchTranslation('vi-VN')">{{$t('element_header.vietnamese')}}</a>
                        </div>
                    </div>
                    <!-- <div class="d-flex-center"><i class="fas fa-globe-asia"></i><span>繁</span></div>  -->
                </div>
            </div>
        </nav>
        <form id="login_form" :action="fmtFormAction" method="post">
            <input id="login_provider" type="hidden" name="provider">
            <input id="inv" type="hidden" name="inv">
            <input id="wannaJoin" type="hidden" name="wannaJoin">
        </form>
    </header>
</template>

<script>
    import Vue from "vue";
    import axios from "axios";
    import VueAxios from "vue-axios";
    import SearchBox from "@/components/Elements/SearchBox2.vue";
    import { ghelper, getCookie } from "@/lib/ghelper";
    import UserNotificationPanel from '@/components/Elements/UserNotificationPanel.vue';

    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_OLD_SERVER_BASE_URL;

    import VModal from "vue-js-modal";
    import "vue-js-modal/dist/styles.css";
    Vue.use(VModal, { dialog: true });

    export default {
        components: {
            SearchBox,
            UserNotificationPanel,
        },
        props: {
            user: {
                Type: Object,
                default: null,
            },
            showSearchBox: {
                Type: Boolean,
                default: true,
            },
            wannaJoin: {
                Type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                isPhone: false,
                categories: null,
                searchLex: null,
                locale: null,

                notificationCount: 0,
            };
        },
        methods: {
            onNotificationCountUpdated: function (c) {
                this.notificationCount = c;
            },
            switchTranslation: async function (locale) {
                if (this.user) {
                    var loader = this.$loading.show();
                    try {
                        let res = await this.axios.post("Account/SetLangUI", {
                            l: locale,
                        });

                        let data = res.data;
                        if (data.success) {
                            this.setLocale(locale);
                        } else {
                            this.$dialog.alert(data.message);
                        }
                        loader.hide();
                    } catch (error) {
                        if (error.response) {
                            // 當狀態碼不在 validateStatus 設定的範圍時進入, 401 感覺會跑來這。待確認
                            // 有 data / status / headers 參數可用
                            console.log(error.response.status);
                        } else if (error.request) {
                            // 發送請求，但沒有接到回應
                            // error.request is an instance of XMLHttpRequest in the browser
                            // and an instance of http.ClientRequest in node.js
                            console.log(error.request);
                        } else {
                            // 在設定 request 時出錯會進入此
                            console.log("Error", error.message);
                        }
                        this.$dialog.alert("無法設定語言。發生不知名的錯誤");
                        loader.hide();
                    }
                } else {
                    this.setLocale(locale);
                }
            },
            setLocale: function (locale) {
                document.cookie = "ui_lang = " + locale + '; path=/';
                this.locale = locale;
                this.$i18n.locale = locale;
            },
            onLoadAdminClick: function () {
                this.loadAdmin = true;
            },
            alert: function (msg) {
                this.$modal.show("dialog", {
                    title: "請注意",
                    text: msg,
                    buttons: [
                        {
                            title: "關閉",
                        },
                    ],
                });
            },
            login: function (providerName, isLogin) {
                if (isLogin) {
                    this.$gtag.event('Login', {
                        'event_category': 'iyuyan',
                        'event_label': 'header',
                        'value': 1
                    });
                } else {
                    this.$gtag.event('Register', {
                        'event_category': 'iyuyan',
                        'event_label': 'header',
                        'value': 1
                    });
                }

                if (this.$route && this.$route.query) {
                    let invCode = this.$route.query.inv;
                    $('#inv').val(invCode);
                }

                $('#login_provider').val(providerName);

                if (this.wannaJoin) {
                    $('#wannaJoin').val(this.wannaJoin);
                }
                
                $('#login_form').submit();
            },
            fmtCategoryUrl: function (cate) {
                return (
                    ghelper.getActionUrl("CategoryShowBS", "Video") + "?cate=" + cate.Id
                );
            },
            getAbsoluteAction: function (action, ctrl) {
                return ghelper.getActionUrl(action, ctrl);
            },
            getAvailableCategories: function () {
                Vue.axios
                    .post("Video/GetAvailableCategories")
                    .then((res) => {
                        var data = res.data;
                        if (data.success) {
                            this.categories = data.data;
                        } else if (data.success == false) {
                            // user not logined
                            this.alert(data.message);
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            // 當狀態碼不在 validateStatus 設定的範圍時進入, 401 感覺會跑來這。待確認
                            // 有 data / status / headers 參數可用
                            switch (error.response.status) {
                                case 401:
                                    alert("與伺服器的連線已過期或者您沒有存取的權限，請重新登入。");
                                    window.location.reload();
                                    return;

                                default:
                                    alert(
                                        "發生嚴重的錯誤狀態, http status code: " +
                                        error.response.status
                                    );
                                    return;
                            }
                        } else if (error.request) {
                            // 發送請求，但沒有接到回應
                            // error.request is an instance of XMLHttpRequest in the browser
                            // and an instance of http.ClientRequest in node.js
                            console.log(error.request);
                        } else {
                            // 在設定 request 時出錯會進入此
                            console.log("Error", error.message);
                        }
                        this.alert("無法取得使用者登入資訊，發生不知名的錯誤。");
                    });
            },
            getSupportedLangCharsets: async function () {
                var loader = this.$loading.show();
                try {
                    let res = await this.axios.post("Data/GetLangSettings", {
                        lls: this.userProfile.LangSystemNative,
                        kc: 'LANG_CHAR_SET',
                    });

                    let data = res.data;
                    if (data.success) {
                        this.nativeLangCharsets = data.data;
                    } else {
                        this.$dialog.alert(data.message);
                    }
                    loader.hide();
                } catch (error) {
                    if (error.response) {
                        // 當狀態碼不在 validateStatus 設定的範圍時進入, 401 感覺會跑來這。待確認
                        // 有 data / status / headers 參數可用
                        console.log(error.response.status);
                    } else if (error.request) {
                        // 發送請求，但沒有接到回應
                        // error.request is an instance of XMLHttpRequest in the browser
                        // and an instance of http.ClientRequest in node.js
                        console.log(error.request);
                    } else {
                        // 在設定 request 時出錯會進入此
                        console.log("Error", error.message);
                    }
                    this.$dialog.alert("無法取得使用者的字元集設定。發生不知名的錯誤");
                    loader.hide();
                }
                return this.learningLangCharsets;
            },
            loadLocale: function () {
                let localeInDb = null;

                if (this.user) {
                    localeInDb = this.user.LangUI;
                    this.getAvailableCategories();
                }

                let localeInCookie = getCookie('ui_lang');

                if (localeInDb) {
                    this.setLocale(localeInDb);
                    this.locale = localeInDb;
                    this.$i18n.locale = localeInDb;
                } else {
                    if (localeInCookie) {
                        this.setLocale(localeInCookie);
                        this.locale = localeInCookie;
                        this.$i18n.locale = localeInCookie;
                    }
                }
            },
        },
        mounted: function () {
            this.loadLocale();
        },
        watch: {
            user: function (newVal) {
                if (newVal) {
                    this.loadLocale();
                }
            },
        },
        computed: {
            fmtMaterialIndexUrl: function () {
                let url = null;
                if (this.user) {
                    url = this.getAbsoluteAction(null, "Material") + '?fl=' + this.user.LearningLang.substring(0, 2);
                } else {
                    url = this.getAbsoluteAction(null, "Material");
                }

                return url;
            },
            fmtTranslationHelpUrl: function () {
                if (this.locale == 'vi-VN') {
                    return 'https://docs.google.com/presentation/d/1MbJnXJqqiSYqbv5q6Z0c_4muRaxCDeDwZ4_VWoeW7_4/edit?fbclid=IwAR0SX9FtTxZ7shJC8FIf8vN_lWVgDKqAmX2x7bz1b_TyltM3ndIcL_OTp4c#slide=id.p';
                } else {
                    return 'https://docs.google.com/presentation/d/1z20HWbyce5iReUkRfyJ4ClehIqNdeSUlUIUfkNnOEF8/edit?usp=sharing';
                }
            },
            fmtRegisterDesc: function () {
                if (this.wannaJoin) {
                    return this.$t('element_header.welcomeWannaJoin');
                } else {
                    return this.$t('element_header.signUpStartStep1');
                }
            },
            fmtCurrentLang: function () {
                switch (this.locale) {
                    case 'vi-VN':
                        return 'Việt';
                    case null:
                    case 'zh-Hant':
                        return '繁中';
                    case 'zh-Hans':
                        return '简中';
                    default:
                        return 'Unk';
                }
            },
            componentMenuAdmin() {
                return () => import("@/components/Elements/MenuAdmin.vue");
            },
            componentMenuContentManager() {
                return () => import("@/components/Elements/MenuContentManager.vue");
            },
            fmtFormAction: function () {
                let appUrl = ghelper.getActionUrl("ExternalLogin", "Account");

                return appUrl;
            },
        },
    };
</script>

<style lang="css">
    .btn:focus,
    .btn.focus {
        box-shadow: none;
    }

    .language-btn {
        white-space: nowrap;
    }

        .language-btn:hover {
            opacity: 0.4;
        }

        .fa-globe-asia,
        .language-btn span {
            color: rgb(130, 130, 130);
        }

    .search-btn {
        border: none;
        width: 36px;
        margin-right: 22px;
    }

        .search-btn img {
            width: 28px;
        }

    header a {
        color: #363636;
    }

    #navbarCollapse {
        width: 100%;
    }

    .login-icon-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
    }

        .login-icon-wrapper img {
            width: 90px;
            margin: 10px;
        }

            .login-icon-wrapper img:hover {
                opacity: 0.5;
            }

    .user-login-wrap {
        cursor: pointer;
        position: relative;
    }

        .user-login-wrap:hover {
            color: #456cebe3;
        }

        .user-login-wrap .dropdown-menu {
            top: 37px;
            padding: 6px 23px !important;
            min-width: auto !important;
        }

    .subscribed-image {
        width: 26px !important;
        height: 26px !important;
        border-radius: 50%;
        overflow: hidden;
        background-color: #a9a9a9;
    }

    .modal-backdrop {
        z-index: 11 !important;
        background-color: rgba(255, 255, 255, 0) !important;
    }

    .modal {
        z-index: 999999 !important;
        background-color: rgba(0, 0, 0, 0.536);
    }

    .signIn-wrap button {
        padding: 0px;
    }

    .notification-item {
        word-break: break-all;
        white-space: normal;
    }

    @media (max-width: 768px) {
        .subscribed-image {
            width: 37px !important;
            height: 37px !important;
            margin-left: 21px !important;
        }

        .nav-hidden-desktop {
            display: block;
        }

        .nav-hidden-mobile {
            display: none;
        }

        .dropdown-menu {
            left: -86px;
        }

        .signUp-wrap .btn-line-board {
            width: 100%;
        }

        .signUp-wrap .w-100 {
            margin: 10px 0px !important;
        }

        .header-search-input-wrapper {
            margin-right: 0px !important;
            margin-bottom: 20px;
        }

        .signIn-wrap button {
            padding: 0.45rem 1.4rem !important;
            width: 100px !important;
            border: 2px solid #456ceb;
            border-radius: 50px;
            background-color: #456ceb;
            color: #fff;
            min-width: 100%;
        }

            .signIn-wrap button:hover {
                color: #fff;
            }
    }

    @media (min-width: 768px) {
        .navbar-expand-md .navbar-collapse {
            justify-content: flex-end;
        }

        .nav-hidden-desktop {
            display: none;
        }

        .nav-hidden-mobile {
            display: block;
        }
    }

    @media (max-width: 768px) {
        .dropdown button {
            padding: 0.45rem 0.8rem 0.45rem 0rem;
        }
    }
</style>