<template lang="html">
    <nav v-if="pageNums && pageNums.length > 1" class="d-flex" aria-label="Page navigation example" >
        <div v-if="!isPhone" class="d-flex mr-1">
            <span class="my-auto" style="min-width:40px;">{{$t('element_pagenation.page')}}</span>
            <select class="form-control mr-2" id="exampleFormControlSelect2" v-model="userPageLen">
                <!-- eslint-disable-next-line -->
                <option v-for="ps in pageSizeSelector" :value="ps">{{ps}}</option>
            </select>
            <span class="my-auto">{{$t('element_pagenation.record')}}</span>
        </div>
        <ul class="pagination justify-content-center">
            <li :class="['page-item', page==1?'disabled':'']">
                <a class="page-link" href="#" tabindex="-1" aria-disabled="true"
                   v-on:click.prevent="gotoPrevPage">&lt;</a>
            </li>
            <!-- eslint-disable-next-line -->
            <li :class="['page-item', page==p?'active':'']" v-for="(p, pidx) in pageNums">
                <a class="page-link" href="#" v-on:click.prevent="gotoPage(p)">{{p}}</a>
            </li>
            <li :class="['page-item', page==pageMax?'disabled':'']">
                <a class="page-link" href="#" v-on:click.prevent="gotoNextPage">&gt;</a>
            </li>
        </ul>
    </nav>
</template>

<script>
    /** 
     *  分頁元件
     *  @displayName 愛語言分頁用元件
     */
    export default {
        props: {
            isPhone: {
                type: String,
                default: null,
            },
            pageLen: {
                type: Number,
                default: 10,
            },
            dataTotal: {
                type: Number,
                default: null,
            },
            pageWindow: {
                type: Number,
                default: null,
            },
            pageSizeSelector: {
                Type: Array,
                default: function () {
                    return [4, 8, 12];
                },
            },
        },
        data: function () {
            return {
                page: 1,
                pageNums: null,
                pageMax: null,

                userPageLen: this.pageLen,
            };
        },
        methods: {
            gotoPageDoneCB: function (newPageNo) {
                if (newPageNo < 1 || newPageNo > this.pageMax) {
                    return;
                }

                this.page = newPageNo;

                // prepare for paging ui variables
                var pageLeft = this.page - this.pageWindow;
                if (pageLeft < 1) {
                    pageLeft = 1;
                }
                var pageRight = pageLeft + this.pageWindow * 2 - 1;
                if (pageRight > this.pageMax) {
                    pageRight = this.pageMax;
                }

                this.pageNums = [];
                for (var i = pageLeft; i <= pageRight; i++) {
                    this.pageNums.push(i);
                }
            },
            gotoPrevPage: function () {
                if (this.page != 1) {
                    this.$emit('goto-page', this.page - 1, this.gotoPageDoneCB);
                } else {
                    this.$emit('goto-page', 1, this.gotoPageDoneCB);
                }

                document.activeElement.blur();
            },
            gotoPage: function (p) {
                this.$emit('goto-page', p, this.gotoPageDoneCB);
                document.activeElement.blur();
            },
            gotoNextPage: function () {
                if (this.pageNums && this.page < this.pageMax) {
                    this.$emit('goto-page', this.page + 1, this.gotoPageDoneCB);
                } else {
                    this.$emit('goto-page', this.pageMax, this.gotoPageDoneCB);
                }

                document.activeElement.blur();
            },
        },
        mounted: function () {
            if (this.dataTotal) {
                this.pageMax = Math.ceil(this.dataTotal / this.pageLen);

                this.gotoPageDoneCB(1);
            }
        },
        computed: {

        },
        watch: {
            dataTotal: function (newValue) {
                this.pageMax = Math.ceil(newValue / this.pageLen);

                this.gotoPageDoneCB(1);
            },
            userPageLen: function (newVal) {
                if (newVal != this.pageLen) {
                    this.$emit('page-size-changed', newVal);

                    this.pageMax = Math.ceil(this.dataTotal / newVal);

                    this.gotoPageDoneCB(1);
                }
            },
         },
    };
</script>

<style lang="css">
    .pagination {
        margin-bottom: 0;
    }

    .page-link {
        position: relative;
        display: block;
        padding: 0.5rem 0.75rem;
        margin-left: -1px;
        line-height: 1.25;
        color: #2B2B2B;
        background-color: #fff;
        border: 0px solid #dee2e6;
    }

    .page-item.active .page-link {
        z-index: 1;
        font-weight: 600;
        color: #456CEB;
        background-color: #fff;
        border-color: #fff;
    }
</style>