<!--
    
events:
    unread_notification_count_updated(unread_count)

-->

<template>
    <div class="dropdown-menu dropdown-menu-right"
         style="z-index: 2100000000; min-width: 300px; max-height: 400px; overflow-y: auto;">
        <div class="d-flex mx-auto mb-2">
            <p class="flex-grow-1 my-auto text-center">使用者通知中心</p>
            <button class="btn btn-success btn-sm ml-auto mr-3" style="line-height:1" @click="readAll()">設定已讀</button>
        </div>
        <a v-if="!notifications || notifications.length == 0" class="dropdown-item">無通知</a>
        <a class="dropdown-item notification-item" href="#" v-for="n in notifications" :style="{'background-color':n.IsRead?'unset':'khaki'}"
           :key="n.Id" @click="gotoNotification(n)">
            {{fmtNotificationMsg(n)}}
        </a>
    </div>
</template>

<script>
    import { ghelper } from "@/lib/ghelper";

    export default{
        props: {
            user: {
                Type: Object,
                default: null,
            },
        },
        data(){
            return {
                // notifications
                notificationCount: 0,
                notifications: null,
            }
        },
        watch: {
        },
        methods: {
            fmtNotificationMsg: function (n) {
                return n.TriggerName + ' 在學習記錄(' + n.NotificatoinSourceName + ')上有新留言';
            },
            getUserNotifications: async function () {
                var loader = this.$loading.show();
                try {
                    let res = await this.axios.post("Data/GetUserNotifications", {
                    });

                    let data = res.data;
                    if (data.success) {
                        this.notifications = data.data;
                    } else {
                        this.$dialog.alert(data.message);
                    }
                    loader.hide();
                } catch (error) {
                    if (error.response) {
                        // 當狀態碼不在 validateStatus 設定的範圍時進入, 401 感覺會跑來這。待確認
                        // 有 data / status / headers 參數可用
                        console.log(error.response.status);
                    } else if (error.request) {
                        // 發送請求，但沒有接到回應
                        // error.request is an instance of XMLHttpRequest in the browser
                        // and an instance of http.ClientRequest in node.js
                        console.log(error.request);
                    } else {
                        // 在設定 request 時出錯會進入此
                        console.log("Error", error.message);
                    }
                    this.$dialog.alert("無法取得使用者通知訊息。發生不知名的錯誤");
                    loader.hide();
                }
            },
            gotoNotification: async function (n) {
                var loader = this.$loading.show();
                let oldIsRead = n.IsRead;
                try {
                    let res = await this.axios.post("Data/ReadUserNotification", {
                        nId: n.Id,
                    });

                    let data = res.data;
                    if (data.success) {
                        let notificationData = data.data;
                        let url = ghelper.getActionUrl('Study', 'Material') +
                            '/' + notificationData.VideoId +
                            "/" + notificationData.TeachingMaterialId;

                        location.href = url;
                        this.$set(n, 'IsRead', true);
                        if (!oldIsRead) {
                            this.notificationCount = this.notificationCount - 1;
                        }
                    } else {
                        this.$dialog.alert(data.message);
                    }
                    loader.hide();
                } catch (error) {
                    if (error.response) {
                        // 當狀態碼不在 validateStatus 設定的範圍時進入, 401 感覺會跑來這。待確認
                        // 有 data / status / headers 參數可用
                        console.log(error.response.status);
                    } else if (error.request) {
                        // 發送請求，但沒有接到回應
                        // error.request is an instance of XMLHttpRequest in the browser
                        // and an instance of http.ClientRequest in node.js
                        console.log(error.request);
                    } else {
                        // 在設定 request 時出錯會進入此
                        console.log("Error", error.message);
                    }
                    this.$dialog.alert("無法讀取使用者通知訊息。發生不知名的錯誤");
                    loader.hide();
                }
            },
            getUserUnreadNotificationsCount: async function () {
                try {
                    let res = await this.axios.post("Data/GetUserUnreadNotificationsCount", {
                    });

                    let data = res.data;
                    if (data.success) {
                        let notificationCount = data.data;
                        this.notificationCount = notificationCount;

                        this.$emit('unread_notification_count_updated', notificationCount);
                    } else {
                        this.$dialog.alert(data.message);
                    }
                } catch (error) {
                    if (error.response) {
                        // 當狀態碼不在 validateStatus 設定的範圍時進入, 401 感覺會跑來這。待確認
                        // 有 data / status / headers 參數可用
                        console.log(error.response.status);
                    } else if (error.request) {
                        // 發送請求，但沒有接到回應
                        // error.request is an instance of XMLHttpRequest in the browser
                        // and an instance of http.ClientRequest in node.js
                        console.log(error.request);
                    } else {
                        // 在設定 request 時出錯會進入此
                        console.log("Error", error.message);
                    }
                    this.$dialog.alert("無法取得使用者通知數量。發生不知名的錯誤");
                }
            },
            readAll: async function () {
                var loader = this.$loading.show();
                try {
                    let res = await this.axios.post("Data/ReadAllUserNotifications", {
                    });

                    let data = res.data;
                    if (data.success) {
                        for (let curItem of this.notifications) {
                            this.$set(curItem, 'IsRead', true);
                        }

                        this.notificationCount = 0;
                        this.$emit('unread_notification_count_updated', this.notificationCount);
                    } else {
                        this.$dialog.alert(data.message);
                    }
                    loader.hide();
                } catch (error) {
                    if (error.response) {
                        // 當狀態碼不在 validateStatus 設定的範圍時進入, 401 感覺會跑來這。待確認
                        // 有 data / status / headers 參數可用
                        console.log(error.response.status);
                    } else if (error.request) {
                        // 發送請求，但沒有接到回應
                        // error.request is an instance of XMLHttpRequest in the browser
                        // and an instance of http.ClientRequest in node.js
                        console.log(error.request);
                    } else {
                        // 在設定 request 時出錯會進入此
                        console.log("Error", error.message);
                    }
                    this.$dialog.alert("使用者通知設定為已讀時。發生不知名的錯誤");
                    loader.hide();
                }
            },
        },
        mounted: function() {
            this.getUserUnreadNotificationsCount();
            this.getUserNotifications();
        }
    }
</script>

<style>

</style>
