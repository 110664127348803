import MobileDetect from 'mobile-detect';

var appMixin = {
    data: function () {
        return {
            isPhone: null,
            isTablet: null,

            user: null,
        };
    },
    methods: {
        alert: function (msg) {
            this.$modal.show('dialog', {
                title: '請注意',
                text: msg,
                buttons: [
                    {
                        title: '關閉'
                    }
                ]
            });
        },
        getUserLogin: function () {
            this.axios.post('Account/GetUserLogin', {
                    /* 這個是假的開關。 
                     * mimicLogined: true `為模擬已登入，false` 為未登入 
                     * mimicUserId: 模擬的使用者代碼。使用者登入資訊可以在 mockApi/data/userAccounts.json 裡面調整
                     */
                    mimicLogined: false,
                    mimicUserId: 540,
                })
                .then(res => {
                    var data = res.data;
                    if (data.success) {
                        this.user = data.data;
                    } else if (data.success == false) {
                        // user not logined
                    }
                })
                .catch(error => {
                    if (error.response) {
                        // 當狀態碼不在 validateStatus 設定的範圍時進入, 401 感覺會跑來這。待確認
                        // 有 data / status / headers 參數可用
                        switch (error.response.status) {
                            case 401:
                                alert('與伺服器的連線已過期或者您沒有存取的權限，請重新登入。');
                                window.location.reload();
                                return;

                            default:
                                alert('發生嚴重的錯誤狀態, http status code: ' + error.response.status);
                                return;
                        }
                    } else if (error.request) {
                        // 發送請求，但沒有接到回應
                        // error.request is an instance of XMLHttpRequest in the browser 
                        // and an instance of http.ClientRequest in node.js
                        console.log(error.request)
                    } else {
                        // 在設定 request 時出錯會進入此
                        console.log('Error', error.message)
                    }
                    this.alert('無法取得使用者登入資訊，發生不知名的錯誤。');
                });
        },
    },
    mounted: function () {
    },
    created: function () {
        var md = new MobileDetect(window.navigator.userAgent);
        this.isPhone = md.phone();
        this.isTablet = md.tablet();

        this.getUserLogin();
    },
    computed: {
    },
}

export default appMixin;