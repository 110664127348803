<template lang="html">
    <div class="header-search-input-wrapper hidden-mobile">
        <input type="search" name="q" :placeholder="$t('element_header.searchVocabulary')" aria-label="Search through site content" class="header-search"
               v-model="searchLex">
        <!-- <select class="header-search-select">
        <option>搜尋文本</option>
        <option>搜尋例句</option>
        <option>搜尋片名</option>
        </select> -->
        <select class="header-search-select" v-model="searchLang">
            <option value="vi-VN">VN</option>
            <option value="zh-Hant">繁中</option>
            <option value="zh-Hans">簡中</option>
        </select>
        <!-- <a class="mr-0" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="/Content/Users/gZlm2jZm2Q.jpg" title="湯勺" class="w-100 subscribed-image ml-2" style="cursor: pointer;"></a> -->
        <img alt="" src="/img/search-button-red.svg" class="search-button-red" v-on:click="gotoLexCorpus">
    </div>
</template>
    
<script>
    import { ghelper, getCookie } from '@/lib/ghelper';

    export default {
        props: {
            user: {
                Type: Object,
                default: null,
            },
        },
        data: function() {
            return {
                searchLex: null,
                searchLang: null,
            };
        },
        methods: {
            gotoLexCorpus: function () {
                let trimmedLex = this.searchLex ? this.searchLex.trim() : null;
                if (trimmedLex) {
                    this.$gtag.event('LexCorpus', {
                        'event_category': 'iyuyan',
                        'event_label': 'header',
                        'value': 3
                    });

                    var url = ghelper.getActionUrl('Corpus', 'Lex');

                    trimmedLex = trimmedLex.toLowerCase();
                    if (this.searchLang == 'vi-VN') {
                        location.href = url + '/vi-VN/zh-Hant/' + trimmedLex;
                    } else if (this.searchLang == 'zh-Hant') {
                        location.href = url + '/zh-Hant/vi-VN/' + trimmedLex;
                    } else if (this.searchLang == 'zh-Hans') {
                        location.href = url + '/zh-Hans/vi-VN/' + trimmedLex;
                    }
                } else {
                    this.alert('請輸入要搜尋的詞彙。');
                }
            },
            setSearchLang: function (lang) {
                document.cookie = "serach_lang = " + lang + '; path=/';
                this.searchLang = lang;
            },
        },
        computed: {
            
        },
        watch: {
            searchLang: function (newVal) {
                this.setSearchLang(newVal);
            },
        },
        mounted() {
            let searchLang = getCookie('serach_lang');

            if (searchLang) {
                this.searchLang = searchLang;
            } else {
                this.searchLang = 'vi-VN';
            }
        },
    };
</script>

<style>
    .header-search-input-wrapper {
        position: relative;
    }

    .header-search {
        background: #F0F0F0 !important;
        border-radius: 50px;
        border: none;
        width: 100%;
        padding: 14px 70px 14px 20px;
    }

    .header-search-select {
        position: absolute;
        border: none;
        right: 60px;
        top: 16px;
        border-left: 1px solid;
        padding-left: 10px;
        background: #F0F0F0 !important;
        cursor:pointer;
    }

        .header-search-select:focus {
            outline: none;
        }

    .header-search:focus {
        border-radius: 50px !important;
        border: none;
        outline: none;
        box-shadow: 0 0 0 0.25rem #456cebe3;
    }
</style>