<template lang="html">
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-md-2 mb-4">
                    <a href="/"><img src="/img/footer-logo.png" alt=""></a>
                </div>
                <div class="col-md-6">
                    <p class="text-second mb-1">{{$t('element_footer.aboutUs')}}</p>
                    <p class="aboutus-wrapper">
                        <a class="mr-2" href="https://medium.com/iyuyan/%E5%AD%B8%E7%BF%92%E5%BF%83%E6%B3%95/home" target="_blank">{{$t('element_footer.learnExperience')}}</a>
                        <a class="mr-2" href="/Home/Preparation">{{$t('element_footer.helpCommunity')}}</a>
                        <a class="mr-2" href="/Home/FAQ">{{$t('element_footer.helpCenter')}}</a>
                        <a class="mr-2" href="/Home/Contact">{{$t('element_footer.contactUs')}}</a>
                    </p>
                    <p class="text-second mb-1">{{$t('element_footer.themeOrientedClasses')}}</p>
                    <p class="aboutus-wrapper">
                        <a class="mr-2" href="/Speaking/About" v-on:click="moreSC('footer')">{{$t('element_footer.aboutToc')}}</a>
                        <a class="mr-2" href="/Speaking/Index">{{$t('element_footer.listRoom')}}</a>
                    </p>
                    <p class="text-second mb-1">{{$t('element_footer.track')}}</p>
                    <p>
                        <a class="mr-2" href="https://www.facebook.com/iyuewen/" target="_blank">{{$t('element_footer.fansPage')}}</a>
                        <a class="mr-2" href="https://www.facebook.com/groups/1890144617701783/" target="_blank">{{$t('element_footer.facebook')}}</a>
                        <a class="mr-2" href="https://medium.com/iyuyan" target="_blank">{{$t('element_footer.blog')}}</a>
                    </p>
                    <p class="text-second mb-1">{{$t('element_footer.termsOfService')}}</p>
                    <p>
                        <a class="mr-2" href="/tos.html">{{$t('element_footer.termsOfService')}}</a>
                        <a class="mr-2" href="/privacy.html">{{$t('element_footer.privacyPolicy')}}</a>
                    </p>
                </div>
                <div class="col-md-4">
                    <div id="fb-group" class="fb-group"
                         data-href="https://www.facebook.com/groups/389687706207143"
                         data-width="300"
                         data-show-metadata="true">
                    </div>
                    <!--
                       <img src="@/assets/image/footer-social-img.png" class="w-100" alt="..."> 
                    -->
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    // load facebook comment scripts
    var tag = document.createElement('script');

    tag.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v12.0";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    export default {
        props: {

        },
        data: function () {
            return {
            };
        },
        methods: {
            moreSC: function (lbl) {
                this.$gtag.event('MoreSC', {
                    'event_category': 'iyuyan',
                    'event_label': lbl,
                    'value': 10
                });
            },
        },
        mounted: function () {
        },
        computed: {

        },
        created() {
            // Kiểm tra hàm đã tồn tại hay chưa, chưa có thì mới tạo, ko sẽ chạy render 2 lần.
            if (!window.fbAsyncInit) {
                window.fbAsyncInit = function () {
                    // eslint-disable-next-line
                    if (typeof (FB) != 'undefined' && FB != null) {
                        // eslint-disable-next-line
                        FB.init({
                            appId: '252521361855678',
                            autoLogAppEvents: true,
                            xfbml: true,
                            version: 'v12.0'
                        });
                        // eslint-disable-next-line
                        FB.AppEvents.logPageView();
                    }
                };

            }
            else
                window.fbAsyncInit();
        },
    };
</script>

<style lang="css">
    footer {
        margin-top: 100px;
        background-color: #F2F2F2;
        border-top: 1px solid rgba(0, 0, 0, 0.125);
        padding: 40px;
    }

        footer img {
            width: 130px;
        }

        footer a {
            color: #707070;
            text-decoration: none;
        }

            footer a:hover {
                color: #DD0018;
                text-decoration: none;
            }

    .footer-title {
        font-weight: 600;
        color: #DD0018;
        font-size: 17px;
    }

    .aboutus-wrapper {
        display: flex;
        flex-wrap: wrap;
    }

        .aboutus-wrapper a {
            display: block;
        }

    /* lg */
    @media (min-width: 992px) {
    }
</style>